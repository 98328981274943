import React, {  useState, createContext} from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from "react-router-dom";

import Router from "./routes/Router";




export const AuthContext = createContext(null);


export const AuthProvider = ({ children }) => {
  const key=1
  const [auth, setAuth] = useState(null);
  const [auth1, setAuth1] = useState([]);
  const login = (CUSCOD)=> setAuth(CUSCOD);
  const line = (USERID_LINE) => setAuth1(USERID_LINE);
  localStorage.setItem(key, auth) 

  return (
    <AuthContext.Provider value={{ auth, login , line,auth1}}>
      {children}
    </AuthContext.Provider>
  );
};


const App = () => {
  
  return (  
    <AuthProvider>    
      <BrowserRouter>  
        <Router />       
      </BrowserRouter>     
    </AuthProvider>   
  );


}

export default App;
