import React from 'react'
import { Route, Redirect } from "react-router-dom";

const LogoutRoute = ({ children }) => {


    const aa = JSON.parse(localStorage.getItem('user'))
  
  
    return <Route>{aa=== null ? children : <Redirect to='/logout' />}</Route>
  
}

export default LogoutRoute
