import React, { useState, useCallback, useEffect } from 'react'
import axios from 'axios'
import { Link, Route, Switch } from 'react-router-dom';

import Slip from '../components/Slip';
import { Row, Col } from 'antd';
import Slip1 from '../components/Slip1';
import Tn_table from '../components/Tn_table';
import Re from '../components/re';

import '../App.css';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
const Cardd = () => {
    const [greeting, setGreeting] = useState({ textseart: '', texttable: 'CONTNO' });
    const [Us, setUs] = useState({ us:'' })

    const handleChange = event => {
        const { name, value } = event.target
        setGreeting({ ...greeting, [name]: value });

    }

    const [USER, setUSER] = useState([])
    const [USER1, setUSER1] = useState({ textseart: 1, texttable: 'REGNO' })
    const auth = JSON.parse(localStorage.getItem("user"));

    const url = 'https://asia-east2-fir-express-c1aec.cloudfunctions.net/aa';

    var onConfirm = async () => {
        const data = []

        if (greeting.textseart === '') {

            await axios.get(`${url}/loans/mers/${USER1.texttable}/${USER1.textseart}`)
                .then(res => {
                    
                    res.data.forEach(element => {
                        data.push(element)

                    })
                    setUSER(data)
                    localStorage.setItem("cus", JSON.stringify(data));
                }).catch(err => {

                });
        } else {
            await axios.get(`${url}/loans/mers/${greeting.texttable}/${greeting.textseart}`)
                .then(res => {
                   // console.log(res.data)
                    res.data.forEach(element => {
                        data.push(element)

                    })
                    setUSER(data)
                    localStorage.setItem("cus", JSON.stringify(data));
                }).catch(err => {

                });
        }


    }



    const feed1 = useCallback(() => {
      //  console.log(auth)
        setUs(auth.accessToken)
        const data = []
        axios.get(`${url}/loans/customers`, {
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${auth.accessToken}`
            },
        })
            .then(res => {
              //  console.log(res.data)
                res.data.forEach(element => {
                    data.push(element)

                })
                setUSER(data)

                localStorage.setItem("cus", JSON.stringify(data));
            }).catch(err => {


            });
    }, [])

    useEffect(() => {

        if (auth.role === 'admin' || auth.role === 'lawyer') {

        } else {
            feed1()

        }



        return () => {

        }
    }, [auth.cuscod])


    return (
        <>
            {auth.role === 'lawyer' || auth.role === 'admin' || auth.role === 're_8_1'|| auth.role === 'b3'  ? (
                <ControlledOpenSelect 
                    headline={greeting.textseart} line={greeting.texttable} onChangeHeadline={handleChange} onConfirm={onConfirm}>
                </ControlledOpenSelect>
            ) : (
                <>
                </>
            )}
            {auth.role !== 'lawyer' && auth.role !== 'b3' ? (
                auth.role === 're_8_1' ? (
                    <Switch>
                        <Route exact path='/card'>
                            <div className="container">
                                <div className="row mt-4">
                                    <div className="col-12 col-md-6 offset-md-3" >
                                        <h2 className="my-4 text-center">กดที่ CARD</h2>
                                        {

                                            USER.map((item) => {

                                                return (
                                                    <div key={item.CONTNO} className="row mt-4">
                                                        <Link to={`/card/Re/${item.CONTNO}/${item.MA}`}>
                                                            <div className="col-12 col-md-6 offset-md-3" >

                                                                <div className="card">
                                                                    <img src={require('../assets/บัตรจ่ายค่างวด.png')} className="card-img-top" alt="..." />
                                                                    <div className="contno">
                                                                        {item.CONTNO}
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </Link>


                                                    </div>



                                                );
                                            })
                                        }




                                    </div>
                                </div>
                            </div>
                        </Route>

                        < Route exact path='/card/Re/:user/:ma'>
                            <Re />
                        </Route >

                    </Switch>
                ) : (
                    <Switch>
                        <Route exact path='/card'>
                            <div className="container">
                                <div className="row mt-4">
                                    <div className="col-12 col-md-6 offset-md-3" >
                                        <h2 className="my-4 text-center">กดที่ CARD</h2>
                                        {

                                            USER.map((item) => {
                                                
                                                return (

                                                    <div key={item.CONTNO} className="row mt-4">
                                                        <Link to={`/card/${item.CONTNO}/${item.MA}`}>
                                                            <div className="col-12 col-md-6 offset-md-3" >

                                                                <div className="card">
                                                                    <img src={require('../assets/บัตรจ่ายค่างวด.png')} className="card-img-top" alt="..." />
                                                                    <div className="contno">
                                                                        {item.CONTNO}
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </Link>
                                                        <div className="col-12 col-md-6 offset-md-3" >
                                                            <div className="card-body"  >
                                                                <Row>
                                                                    <Col span={24} style={{ textAlign: 'center' }}>
                                                                        <Link to={`/card/slip1/${item.CONTNO}/${item.MA}`}>
                                                                            <button type="button" className="btn btn-outline-primary btn-lg btn-block">รายละเอียด</button>
                                                                        </Link>
                                                                    </Col>

                                                                </Row>
                                                            </div>
                                                        </div>

                                                    </div>
                                                );
                                            })
                                        }

                                    </div>
                                </div>
                            </div>
                        </Route>

                        < Route exact path='/card/:user/:ma'>
                            <Slip />
                        </Route >
                        < Route path='/card/slip1/:user/:ma'>
                            <Slip1 />
                        </Route >
                    </Switch>

                )




            ) : (
                <Switch>
                    <Route exact path='/card'>
                        <div className="container">
                            <div className="row mt-4">
                                <div className="col-12 col-md-6 offset-md-3" >
                                    <h2 className="my-4 text-center">กดที่ CARD</h2>
                                    {

                                        USER.map((item) => {
                                           
                                            return (
                                                <div key={item.CONTNO} className="row mt-4">
                                                    <Link to={`/card/${item.CONTNO}/${item.MA}`}>
                                                        <div className="col-12 col-md-6 offset-md-3" >

                                                            <div className="card">
                                                                <img src={require('../assets/บัตรจ่ายค่างวด.png')} className="card-img-top" alt="..." />
                                                                <div className="contno">
                                                                    {item.CONTNO}
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </Link>


                                                </div>



                                            );
                                        })
                                    }




                                </div>
                            </div>
                        </div>
                    </Route>

                    < Route exact path='/card/:user/:ma'>
                        <Tn_table />
                    </Route >

                </Switch>
            )}




        </>
    )
}

export default Cardd





const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
}));

const BootstrapInput = withStyles((theme) => ({
    formadmin: {
        marginleft: 135
    },
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);


function ControlledOpenSelect({ headline, onChangeHeadline, line, onConfirm }) {
    const classes = useStyles();
    const [Seart, setSeart] = React.useState({ textseart: '', texttable: 'เลขบัตรประชาชน' });
    const [open, setOpen] = React.useState(false);
   

    const handleChange = (event) => {
        const { name, value } = event.target
        setSeart({ ...Seart, [name]: value })
        this.props.confirm(Seart)

    };


    return (
        <div >
            <Container maxWidth="md" >
                <form className="formadmin" onSubmit={e => {
                    e.preventDefault()
                    onConfirm()
                }} >

                    <FormControl className={classes.margin}>

                        <InputLabel htmlFor="demo-customized-textbox">ข้อความค้นหา</InputLabel>
                        <BootstrapInput id="demo-customized-textbox"
                            name="textseart"
                            value={headline.textseart}
                            onChange={onChangeHeadline}
                            input={<BootstrapInput />}
                        />

                    </FormControl>

                    <FormControl className={classes.margin}>
                        <InputLabel htmlFor="demo-customized-select-native">หัวข้อค้นหา</InputLabel>
                        <NativeSelect
                            id="demo-customized-select-native"
                            name="texttable"
                            value={line.texttable}
                            onChange={onChangeHeadline}
                            input={<BootstrapInput />}
                        >
                            <option value="CONTNO">เลขที่สัญญา</option>
                            <option value="CUSCOD">เลขบัตรประชาชน</option>
                            <option value="NAME1">ชื่อ</option>
                            <option value="NAME2">สกุล</option>
                            <option value="ENGNO">เลขตัวถัง</option>
                            <option value="REGNO">ทะเบียนรถ</option>
                            <option value="STRNO">เลขโฉนด</option>
                        </NativeSelect>
                    </FormControl>
               
                                <Button variant="contained" size="large" color="primary" style={{ marginTop: 25 }} onClick={onConfirm}>
                                    <SearchIcon style={{ fontSize: 35 }} />
                                </Button>
                        
                </form>
            </Container>



        </div>
    );
}
