import React from 'react';
import '../App.css';
import { useHistory } from "react-router-dom";


const Example = () => {
  const history = useHistory()
  var logout = () => {
    localStorage.clear();

  }

  return (
    <ul className="nav justify-content-end" style={{ background: '#1d4080' }}>
      <li className="nav-item" >
        <button type="button" className="btn btn-outline-light btn-lg btn-block" onClick={() => {
          logout();
          history.push("/login");
        }} >logout</button>
      </li>
    </ul>
  )
};

export default Example