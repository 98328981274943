import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';
import ReactToPrint from 'react-to-print';
import { useParams, useHistory } from 'react-router-dom'
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    container: {
        marginTop: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
    },
}));

export default function UserList() {
    const url = 'https://asia-east2-fir-express-c1aec.cloudfunctions.net/aa/loans';
    const auth = JSON.parse(localStorage.getItem("user"));
    const classes = useStyles();
    const { user, ma } = useParams()
    const [users, setUsers] = useState([]);
    const [cus_user, setCus_user] = useState([]);
    const [cus, setCus] = useState([]);
    const [kong, setkong] = useState();
    const [sumton, setSumton] = useState();
    const [sumdok, setSumdok] = useState();
    const [sumdok2, setSumdok2] = useState();
    const [dok, setDok] = useState();
    const [sumdok_kang, setSumdok_Kang] = useState();
    const [sum_pay, setSum_Pay] = useState();
    const [sum_delay_dok, setSum_delay_dok] = useState();
    const [sum_ton2, setSum_ton2] = useState();
    const [sum_pay_dok_kang, setSum_pay_dok_kang] = useState();
    const [sum_pay_dok, setSum_pay_dok] = useState();
    useEffect(() => {
        UsersGet()
    }, [])

    const UsersGet = async () => {
        if (auth.role === 'b3') {
            await axios.get(`https://asia-east2-fir-express-c1aec.cloudfunctions.net/aa/loans/gettest1/${user}/${ma}`)
                .then(res => {
                    setUsers(res.data)
                   console.log("aa")
                    const last = res.data.length - 1
                    const kong = res.data[last].kong
                    const sum_pay = res.data.reduce((sum, number) => {
                        return sum + +number.pay
                    }, 0)
                    const sum_dok = res.data.reduce((sum, number) => {
                        return sum + +number.dok
                    }, 0)
                    const sum_dok2 = res.data.reduce((sum, number) => {
                        if (+number.dok2 < 0) {
                            return sum + 0
                        } else {
                            return sum + +number.dok2
                        }

                    }, 0)
                    const sum_ton = res.data.reduce((sum, number) => {
                        return sum + +number.ton1
                    }, 0)
                    const sum_payam = res.data.reduce((sum, number) => {
                        return sum + +number.payamt
                    }, 0)
                    const sum_delay_dok = res.data.reduce((sum, number) => {
                        return sum + +number.delay_dok
                    }, 0)
                    const sum_ton2 = res.data.reduce((sum, number) => {
                        if (+number.ton2 < 0) {
                            return sum + 0
                        } else {
                            return sum + +number.ton2
                        }

                    }, 0)
                    const sum__dok = res.data.reduce((sum, number) => { // ดอกเบี้ยเหลือ
                        return sum + +number.pay_dok_kang * (-1)

                    }, 0)
                    const sum_pay_dok = res.data.reduce((sum, number) => {
                        if (+number.pay_dok < 0) {
                            return sum + 0
                        } else {
                            return sum + +number.pay_dok
                        }
                    }, 0)

                    setSum_pay_dok(sum_pay_dok)
                    setSum_pay_dok_kang(sum__dok)
                    setSum_ton2(sum_ton2)
                    setSum_delay_dok(sum_delay_dok)
                    setSum_Pay(sum_pay)

                  //  setDok(-res.data[last].ton1)
                    setSumton(sum_ton)
                    setSumdok(sum_dok)
                    setSumdok2(sum_dok2)
                    setSumdok_Kang(sum_payam)

                })
            await axios.get(`https://asia-east2-fir-express-c1aec.cloudfunctions.net/aa/loans/getcusmast/${user}/${ma}`)
                .then(res => {
                    setCus_user(res.data)

                })
                await axios.post(`${url}/loans/custmast/` + user + `/` + ma)
                .then(res => {             
                    setCus(res.data[0])            
                }).catch(err => {
    
                });
        } else {
            await axios.get(`https://asia-east2-fir-express-c1aec.cloudfunctions.net/aa/loans/gettest/${user}/${ma}`)
                .then(res => {
                    setUsers(res.data)
                    //  console.log(res.data)
                    const last = res.data.length - 1
                    const kong = res.data[last].kong
                    const sum_pay = res.data.reduce((sum, number) => {
                        return sum + +number.pay
                    }, 0)
                    const sum_datekang = res.data.reduce((sum, number) => {
                        return sum + +number.datekang
                    }, 0)
                    setSum_Pay(sum_pay)
                    setkong(kong)
                    setDok(sum_datekang)
                    setSumton(res.data[last].a)
                    setSumdok(res.data[last].b)
                    setSumdok_Kang(res.data[last].c)
                    console.log(res.data)
                })
            await axios.get(`https://asia-east2-fir-express-c1aec.cloudfunctions.net/aa/loans/getcusmast/${user}/${ma}`)
                .then(res => {
                    console.log(res.data)
                    setCus_user(res.data)
                })
        }



    }

    const linkToPrint = () => {
        return (
            <button className="print">Click To PrintOF Body</button>
        )
    }
    const componentRef = useRef();


    return (
        <>
            <ReactToPrint trigger={linkToPrint} content={() => componentRef.current} />

            <div className="containertable" style={{ marginTop: '2rem' }} ref={componentRef} >






                <table className="table table-sm" style={{ textAlign: 'center', border: '5px solid #f0f0f000' }}>
                    <thead>
                    {auth.role != 'b3' ? (
                                           <td colspan="24" style={{ textAlign: 'center', borderTop: '0px solid #eee' }}>

                                           <div className="contentslip1" >
                                               <p className='pslip1'>เลขที่สัญญา:</p>
                                               <p className='pslip1'>{cus_user.CONTNO}</p>
                                               <p className='pslip1'>อำเภอ:</p>
                                               <p className='pslip2' >{cus_user.MODEL}</p>
                                               <p className='pslip3'>ประเภท:</p>
                                               <p className='pslip3'>{cus_user.COLOR}</p>
                                               <p className='pslip3'>เลขโฉนด:</p>
                                               <p className='pslip3'>{cus_user.STRNO}</p>
                                           </div>
                                           <div className="contentslip2">
                                               <p className='pslip1'>วันเริ่มทำสัญญา:</p>
                                               <p className='pslip2' >{cus_user.SDATE}</p>
                                               <p className='pslip3'>ชำระงวดแรก:</p>
                                               <p className='pslip3'>{cus_user.DDATE}</p>
                                               <p className='pslip3'>คงเหลือ:</p>
                                               {auth.role != 'b3' ? (
                                                   <p className='pslip3'>{Number(kong).toLocaleString('en')}</p>
                                               ) : (
                                                   <p className='pslip3'>{Number((cus_user.TCSHPRC - sum_ton2) + (sumdok - sumdok2) + (sum_delay_dok - sum_pay_dok)).toLocaleString('en')}</p>
                                               )
                                               }
                                               <p className='pslip3'>ผ่อน:</p>
                                               <p className='pslip3'>{cus_user.T_NOPAY}</p>
                                               <p className='pslip3'>งวด</p>
                                               <p className='pslip3'>งวดละ:</p>
                                               <p className='pslip3'>{Number(cus_user.TOT_UPAY).toLocaleString('en')}</p>
                                               <p className='pslip3'>บาท</p>
                                           </div>
               
               
                                           <div style={{ textAlign: 'center', color: 'red' }}>
                                               ชื่อผู้ทำสัญญา {cus_user.SNAM} {cus_user.NAME1} {cus_user.NAME2}
                                           </div>
                                           <div style={{ textAlign: 'center', color: 'red' }}>
                                               ยอดกู้ {Number(cus_user.TCSHPRC).toLocaleString('en')}
                                           </div>
               
               
                                       </td>
                                ) : (
                                    <td colspan="24" style={{ textAlign: 'center', borderTop: '0px solid #eee' }}>

                                    <div className="contentslip1" >
                                        <p className='pslip1'>เลขที่สัญญา:</p>
                                        <p className='pslip1'>{cus_user.CONTNO}</p>
                                        <p className='pslip1'>ทะเบียนรถ:</p>
                                        <p className='pslip2' >{cus.REGNO}</p>
                                        <p className='pslip3'>ยี่ห้อ:</p>
                                        <p className='pslip3'>{cus.TYPE}</p>
                                        <p className='pslip3'>เกรด:</p>
                                        <p className='pslip3'>{cus.GRDCOD}</p>
                                    </div>
                                    <div className="contentslip2">
                                        <p className='pslip1'>วันเริ่มทำสัญญา:</p>
                                        <p className='pslip2' >{cus_user.SDATE}</p>
                                        <p className='pslip3'>ชำระงวดแรก:</p>
                                        <p className='pslip3'>{cus_user.DDATE}</p>
                                        <p className='pslip3'>คงเหลือ:</p>
                                        {auth.role != 'b3' ? (
                                            <p className='pslip3'>{Number(kong).toLocaleString('en')}</p>
                                        ) : (
                                            <p className='pslip3'>{Number((cus_user.TCSHPRC - sum_ton2) + (sumdok - sumdok2) + (sum_delay_dok - sum_pay_dok)).toLocaleString('en')}</p>
                                        )
                                        }
                                        <p className='pslip3'>ผ่อน:</p>
                                        <p className='pslip3'>{cus_user.T_NOPAY}</p>
                                        <p className='pslip3'>งวด</p>
                                        <p className='pslip3'>งวดละ:</p>
                                        <p className='pslip3'>{Number(cus_user.TOT_UPAY).toLocaleString('en')}</p>
                                        <p className='pslip3'>บาท</p>
                                    </div>
        
        
                                    <div style={{ textAlign: 'center', color: 'red' }}>
                                        ชื่อผู้ทำสัญญา {cus_user.SNAM} {cus_user.NAME1} {cus_user.NAME2}
                                    </div>
                                    <div style={{ textAlign: 'center', color: 'red' }}>
                                        ยอดกู้ {Number(cus_user.TCSHPRC).toLocaleString('en')}
                                    </div>
        
        
                                </td>
                                )
                                }
                 
                        {auth.role != 'b3' ? (
                            <tr>
                                <th scope="col">ครั้งที่จ่าย</th>
                                <th scope="col">วันที่ชำระ</th>
                                <th scope="col">จำนวนวันที่ค้าง</th>
                                <th scope="col">ยอดเงินชำระ</th>
                                <th scope="col">ดอกเบี้ย</th>
                                <th scope="col">เงินต้น</th>
                                <th scope="col">ดอกเบี้ยที่ค้าง</th>
                                <th scope="col">ต้นคงเหลือ</th>
                            </tr>
                        ) : (
                            <tr>
                                <th scope="col">ครั้งที่จ่าย</th>
                                <th scope="col">วันครบกำหนดชำระ</th>
                                <th scope="col">ค่างวด</th>
                                <th scope="col">ดอกเบี้ย</th>
                                <th scope="col">เงินต้น</th>
                                <th scope="col">วันล่าช้า</th>
                                <th scope="col">ดอกเบี้ยล่าช้า</th>
                                <th scope="col">วันที่ชำระ</th>
                                <th scope="col">ชำระงวดนี้</th>
                                <th scope="col">ชำระดอกเบี้ยคงเหลืองวดที่แล้ว</th>
                                <th scope="col">ชำระดอกเบี้ยล่าช้า</th>
                                <th scope="col">ชำระดอกเบี้ย</th>
                                <th scope="col">ชำระเงินต้น</th>
                                <th scope="col">ดอกเบี้ยคงเหลือ</th>
                                <th scope="col">เงินต้นคงเหลือ</th>
                                <th scope="col">จำนวนวันในเดือน</th>
                            </tr>
                        )
                        }


                    </thead>
                    <tbody>
                        {

                            users.map((user, index) => {





                                return (
                                    auth.role != 'b3' ? (
                                        <tr key={index}>
                                            <th scope="row">{index + 1}</th>
                                            <td>{user.dat}</td>
                                            <td>{user.dat == '-' && '-'}{user.dat != '-' && user.datekang}</td>
                                            <td>{Number(Math.round(user.pay)).toLocaleString('en')}</td>
                                            <td>{user.ton > 0 && Number(user.dok).toLocaleString('en')}{user.ton <= 0 && Number(+user.dok).toLocaleString('en')}</td>
                                            <td>{user.ton > 0 && Number(user.ton).toLocaleString('en')}{user.ton <= 0 && 0}</td>
                                            {user.dat != '-' ? (
                                                <td> {user.ton < 0 && Number(-user.ton).toLocaleString('en')}{user.ton > 0 && 0}{user.ton == 0 && Number(user.ton).toLocaleString('en')}</td>
                                            ) : (
                                                <td>{user.dat == '-' && '-'}</td>
                                            )
                                            }
                                            <td>{Number(user.kong).toLocaleString('en')} </td>
                                        </tr>

                                    ) : (
                                        <tr key={index}>
                                            {/* ครั้งที่จ่าย*/}
                                            <th scope="row">{index + 1}</th>
                                            {/*   วันครบกำหนดชำระ  */}
                                            <td>{user.dat}</td>
                                            {/*   ค่างวด*/}
                                            <td>{Number(Math.round(user.pay)).toLocaleString('en')}</td>
                                            {/*   ดอกเบี้ย*/}
                                            <td>{user.ton1 > 0 && Number(user.dok).toLocaleString('en')}{user.ton1 <= 0 && Number(+user.dok).toLocaleString('en')}</td>
                                            {/*   เงินต้น*/}
                                            <td>{user.ton1 > 0 && Number(user.ton1).toLocaleString('en')}{user.ton1 <= 0 && 0}</td>
                                            {/*   วันล่าช้า*/}
                                            {user.delay < 0 ? (
                                                <td>0</td>
                                            ) : (
                                                <td>{user.delay}</td>
                                            )
                                            }

                                            {/*   ดอกเบี้ยล่าช้า*/}
                                            <td>{Number(user.delay_dok).toLocaleString('en')}</td>
                                            {/*   วันที่ชำระ*/}
                                            <td>{user.ddate}</td>
                                            {/*   ชำระงวดนี้*/}
                                            <td>{user.payamt}</td>
                                            {/* ชำระดอกเบี้ยคงเหลืองวดที่แล้ว*/}
                                            {user.pay_dok_kang != 0 ? (
                                                <td>{Number(user.pay_dok_kang * (-1)).toLocaleString('en')}</td>
                                            ) : (
                                                <td>0</td>
                                            )
                                            }
                                            {/*   ชำระดอกเบี้ยล่าช้า*/}
                                            {user.pay_dok > 0 ? (
                                                <td>{Number(user.pay_dok).toLocaleString('en')}</td>
                                            ) : (
                                                <td>0</td>
                                            )
                                            }
                                            {/*   ชำระดอกเบี้ย*/}
                                            {user.dat != '-' ? (
                                                user.dok2 < 0 ? (
                                                    <td>0</td>
                                                ) : (
                                                    <td>{Number(user.dok2).toLocaleString('en')}</td>
                                                )

                                            ) : (
                                                <td>0</td>
                                            )

                                            }

                                            {/*   ชำระเงินต้น*/}
                                            {user.dat != '-' ? (
                                                user.ton2 < 0 ? (
                                                    <td>0</td>
                                                ) : (
                                                    <td>{Number(user.ton2).toLocaleString('en')}</td>
                                                )

                                            ) : (
                                                <td>0</td>
                                            )

                                            }
                                            {/*   ดอกเบี้ยคงเหลือ*/}

                                            {user.dok_kang != 0 ? (
                                                <td>{Number(user.dok_kang * (-1)).toLocaleString('en')}</td>
                                            ) : (
                                                <td>0</td>
                                            )
                                            }


                                            {/*   เงินต้นคงเหลือ*/}
                                            <td>{Number(user.kong).toLocaleString('en')} </td>
                                            {/*   จำนวนวันในเดือน*/}
                                            <td>{user.dat == '-' && '-'}{user.dat != '-' && user.datekang}</td>
                                        </tr>

                                    )

                                )

                            })

                        }
                        {auth.role != 'b3' ? (
                            <>
<tr>
                                <td colSpan="13">
                                    <br></br>
                                </td>
                            </tr><tr>

                                    <td colSpan="2">TOTAL</td>
                                    <td >{Number(dok).toLocaleString('en')}</td>                                
                                    <td>{Number(sum_pay).toLocaleString('en')}</td>
                                    <td>{Number(sumdok).toLocaleString('en')}</td>
                                    <td>{Number(sumton).toLocaleString('en')}</td>                                                              
                                    <td></td>                              
                                    <td></td>
                                    <td></td>
                                  
                                </tr>
                            </>
                        ) : (
                            <><tr>
                                <td colSpan="13">
                                    <br></br>
                                </td>
                            </tr><tr>

                                    <td colSpan="2">TOTAL</td>
                                    <td>{Number(sum_pay).toLocaleString('en')}</td>
                                    <td>{Number(sumdok).toLocaleString('en')}</td>
                                    <td>{Number(sumton).toLocaleString('en')}</td>
                                    <td ></td>
                                    <td >{Number(sum_delay_dok).toLocaleString('en')}</td>
                                    <td ></td>
                                    <td>{Number(sumdok_kang).toLocaleString('en')}</td>
                                    <td >{Number(sum_pay_dok_kang).toLocaleString('en')}</td>
                                    <td >{Number(sum_pay_dok).toLocaleString('en')}</td>
                                    <td >{Number(sumdok2).toLocaleString('en')}</td>
                                    <td >{Number(sum_ton2).toLocaleString('en')}</td>
                                    <td ></td>


                                    <td></td>
                                    <td colSpan="2"></td>
                                </tr><tr>

                                    <td colSpan="5"></td>
                                    <td></td>
                                    <td>ดอกเบี้ยคงค้าง</td>
                                    <td></td>
                                    <td>ดอกเบี้ยล่าช้าคงค้าง</td>
                                    <td></td>
                                    <td>เงินต้นคงเหลือ</td>
                                    <td></td>

                                </tr><tr>
                                    <td colSpan="5">คงเหลือ</td>
                                    <td></td>
                                    <td>{Number(sumdok - sumdok2).toLocaleString('en')}</td>
                                    <td></td>
                                    <td>{Number(sum_delay_dok - sum_pay_dok).toLocaleString('en')}</td>
                                    <td></td>
                                    <td>{Number(cus_user.TCSHPRC - sum_ton2).toLocaleString('en')}</td>
                                </tr><tr>
                                    <td colSpan="4"></td>
                                    <td></td>
                                    <td></td>
                                    <td>รวม</td>
                                    <td></td>
                                    <td colSpan="2">{Number((cus_user.TCSHPRC - sum_ton2) + (sumdok - sumdok2) + (sum_delay_dok - sum_pay_dok)).toLocaleString('en')}</td>
                                    <td>บาท</td>
                                    <td></td>

                                </tr></>
                        )
                        }



                    </tbody>


                </table >



            </div >
        </>

    );
}


