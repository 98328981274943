import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import axios from 'axios'
import '../App.css';
import { Row, Col } from 'antd';




const Slip = () => {


    const today = new Date();
    const textId = '0405559001925';
    const textId7Eleven = '0405552000435';
    const suffix = '00';
    const ref1 = '006';
    const amount = '0';
    const history = useHistory()
    const { user, ma } = useParams()
    const [USER, setUSER] = useState([])
    const [PAYD, setPAYD] = useState()
    const [INTAMT, setINTAMT] = useState()
    const [DSCI, setDSCI] = useState()
    const [balan, setbalan] = useState()
    const url = 'https://asia-east2-fir-express-c1aec.cloudfunctions.net/aa';
    const urlqr = 'https://asia-east2-fir-express-c1aec.cloudfunctions.net/aa';
    const auth1 = JSON.parse(localStorage.getItem("cus"));

    const feed1 = async () => {
        const data = []
        const data1 = []
        const data2 = []
        const INTAMT = []
        const DSCI = []
        const balan = []
        await axios.get(`${url}/cus/${user}/${ma}`)
            .then(res => {
                res.data.forEach(element => {
                    balan.push(element.BALAN)

                })
                setbalan(~~balan)

            }).catch(err => {

            });
        await axios.get(`${url}/cusss/${user}/${ma}`)
            .then(res => {
                res.data.forEach(element => {
                    INTAMT.push(element.AA)
                })
                setINTAMT(~~INTAMT)

            }).catch(err => {

            });
        await axios.get(`${url}/customers/${user}/${ma}`)
            .then(res => {
                res.data.forEach(element => {
                    data.push(element)



                })
                setUSER(data)
      //  console.log('customers',data)



            }).catch(err => {

            });
        await axios.get(`${url}/custo/${user}/${ma}`)
            .then(res => {
                res.data.forEach(element => {

                    data1.push(~~element.PAYI)
                    DSCI.push(element.DSCI)


                })
                setPAYD(~~data1)
                setDSCI(~~DSCI)
              //  console.log(data1,DSCI)


            }).catch(err => {

            });
    }




    useEffect(() => {

        const jj = auth1.find(u => u.CONTNO === user)

        if (jj) {
            feed1();

        } else {
            history.push('/card')
        }



        return () => {

        }
    }, [user, history])






    return (


        <>

            {

                USER.map((item) => {

                    var last = item.result.length - 1
                    var g = INTAMT + Math.round(item.tttt)
                    var tt = ~~PAYD - ~~g
                    var StartD = new Date(item.result[0].DDATE)
                    var Start1 = new Date(item.result[0].DATE1)
                    var INTAMT_fee = 0
                    var fee_INTAMT = 0

                    if (Start1.getTime() > StartD.getTime()) {

                        var INTAMT_fee = Math.round(-tt * 0.415667)
                        var fee_INTAMT = Math.round(-tt * 0.5843337)
                        var c = Math.round(item.INTAMT + INTAMT_fee) + Math.round(item.fee + fee_INTAMT)

                        var b = ~~c
                    }
                    else {
                        if (tt === 0) {
                            var tt = 0;
                            var b = Math.round(item.INTAMT) + Math.round(item.fee)
                        } else {
                            if (tt < 0) {

                                var tt = ~~tt
                                var c = Math.round(item.INTAMT) + Math.round(item.fee)
                                if (c >= tt) {
                                    var b = ~~c
                                } else {
                                    var tt = -~~tt
                                    var b = ~~c - ~~tt
                                }

                            } else {
                                var c = Math.round(item.INTAMT) + Math.round(item.fee)

                                var b = ~~c - ~~tt
                            }

                        }
                    }

                    return (

                        <div className="invoice-box" key={item.te1[1]}>
                            <table cellPadding={0} cellSpacing={0}>
                                <tbody>
                                    <tr className="top">
                                        <td colSpan={2}>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <Row>
                                                            <Col span={10} style={{ textAlign: 'center' }}>
                                                                <div ><img src={require('../assets/CAL-Logo(ใส).png')} style={{ width: '100%', maxWidth: 156 }} /></div>
                                                            </Col>
                                                            <Col span={14} style={{ marginLeft: '-10px', marginTop: '30px' }}>
                                                                <Row >
                                                                    <Col span={12} style={{ textAlign: 'right' }} >
                                                                        <div >
                                                                            Date:
                                                        </div>
                                                                    </Col>
                                                                    <Col span={12} style={{ textAlign: 'center' }} >
                                                                        <div >
                                                                            {today.getDate()}/{today.getMonth() + 1}/{today.getFullYear()}
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row >
                                                                    <Col span={12} style={{ textAlign: 'right' }} >
                                                                        <div >
                                                                            name:&nbsp;
                                                        </div>
                                                                    </Col>
                                                                    <Col span={12} style={{ textAlign: 'center' }} >
                                                                        <div >
                                                                            {balan !== 0 && item.result[last].NAME1}{item.result[last].NAME2 !== null && item.result[last].NAME2}
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row >
                                                                    <Col span={12} style={{ textAlign: 'right' }} >
                                                                        <div >
                                                                            ref1:&nbsp;
                                                        </div>
                                                                    </Col>
                                                                    <Col span={12} style={{ textAlign: 'center' }} >
                                                                        <div >
                                                                            {ref1}
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row >
                                                                    <Col span={12} style={{ textAlign: 'right' }} >
                                                                        <div >
                                                                            ref2:&nbsp;
                                                        </div>
                                                                    </Col>
                                                                    <Col span={12} style={{ textAlign: 'center' }} >
                                                                        <div >
                                                                            {item.te1[1]}
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr className="information">
                                        <td colSpan={2}>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ textAlign: 'right' }}>
                                                            { ma !== 'LSFHP' ? (
                                                                <>
                                                                    {ma === 'SFHP' ? (
                                                                        <>
                                                                            {item.result[last].TYPE.trim() === 'โฉนด-ที่ดิน' || item.result[last].BAAB.trim() === 'ที่ดิน' || item.result[last].TYPE.trim() === 'ที่ดิน' ? (
                                                                                item.result[last].STRNO
                                                                            ) : (
                                                                                item.result[last].REGNO
                                                                            )}

                                                                        </>
                                                                    ) : (
                                                                        item.result[last].REGNO                                                                  
                                                                     )}
                                                                </>
                                                            ) : (
                                                                item.result[last].STRNO
                                                            )}


                                                        </td>
                                                    </tr>
                                                </tbody></table>
                                        </td>
                                    </tr>
                                    <tr className="heading">
                                        <td>Bought items:</td>
                                        <td>Price</td>
                                    </tr>
                                    <tr className="item">
                                        <td>วันที่ครบกำหนดชำระ:</td>
                                        <td>{balan !== 0 && item.result[last].DDATE}</td>
                                    </tr>

                                    {
                                        item.result.map((items) => {
                                            return (
                                                <tr className="item" key={items.NOPAY}>
                                                    <td>ค่างวด งวดที่ {items.NOPAY}/{item.result[last].T_NOPAY}: </td>
                                                    <td>{Number(Math.round(~~items.DAMT - ~~items.PAYMENT)).toLocaleString('en')} บาท</td>
                                                </tr>
                                            )
                                        })
                                    }
                                    <tr className="item">
                                        <td>รวมยอดค่างวด:</td>
                                        <td>{balan <= 0 && "0"}{balan > 0 && Number(Math.round(item.DAMT)).toLocaleString('en')} บาท</td>
                                    </tr>


                                    <tr className="heading">
                                        <td>Bought items:</td>
                                        <td>Price</td>
                                    </tr>

                                    <tr className="item">
                                        <td>เบี้ยปรับ:</td>
                                        <td>{Number(Math.round(item.INTAMT + INTAMT_fee)).toLocaleString('en')} บาท</td>
                                    </tr>
                                    <tr className="item">
                                        <td>ค่าธรรมเนียมล่าช้า:</td>
                                        <td>{Number(Math.round(item.fee + fee_INTAMT)).toLocaleString('en')} บาท</td>
                                    </tr>
                                    <tr className="item">
                                        <td>เบี้ยปรับที่ชำระแล้ว:</td>
                                        <td>{tt <= 0 && "0"}{tt > 0 && Number(Math.round(~~tt)).toLocaleString('en')} บาท</td>
                                    </tr>
                                    <tr className="item">
                                        <td>ส่วนลดค่าปรับทั้งหมด:</td>
                                        <td>{Number(Math.round(~~DSCI)).toLocaleString('en')} บาท</td>
                                    </tr>
                                    <tr className="item">
                                        <td>คงเหลือเบี้ยปรับและค่าธรรมเนียม:</td>
                                        <td>{Number(Math.round(~~b)).toLocaleString('en')} บาท</td>
                                    </tr>
                                    <tr className="item">
                                        <td>รวมวันที่ค้างค่างวดทั้งหมด:</td>
                                        <td>{item.DELAY}/วัน</td>
                                    </tr>
                                </tbody></table>
                            <br />
                            <div className="content">
                                <h1 className="justify-center">Total price: </h1>
                                <h2>{balan <= 0 && "0"} {balan > 0 && Number(Math.round(~~item.DAMT + ~~b)).toLocaleString('en')}</h2>
                                <p>THB </p>
                            </div>

                        </div>

                    );

                })

            }








            <div className="invoice-box1">
                {
                    USER.map((items) => {
                        return (
                            <table cellPadding={0} cellSpacing={0} key={items.te1[1]}>
                                <tbody >
                                    <tr className="top">
                                        <td colSpan={2}>
                                            <>
                                                {/*         <Col span={24} style={{ marginLeft: '-13px' }} >
                                                    <img style={{ margin: '21px' }} src={"" + url + "/" + textId + "/" + suffix + "/" + ref1 + "/" + items.te1[1] + "/" + amount} />
                                                </Col>
                                               */}
                                            </>
                                            <Col span={24} style={{}} >
                                                <img className="qr" style={{}} src={"" + urlqr + "/loans/qr/" + textId + "/" + suffix + "/" + ref1 + "/" + items.te1[1] + "/" + amount} />
                                            </Col>
                                        </td>
                                    </tr>.

                                        <p>สำหรับชำระผ่านธนาคาร</p>
                                    <div className="contentqr1">
                                        <p className="tax">เลขประจำตัวผู้เสียภาษี:</p>
                                        <p style={{ color: 'red' }}>{textId}{suffix}</p>
                                    </div>
                                    <div className="contentqr1">
                                        <p className="ref">ref1:</p>
                                        <p className="ref1" style={{ color: 'red' }}>{ref1}</p>
                                    </div>
                                    <div className="contentqr1">
                                        <p className="ref">ref2:</p>
                                        <p className="ref2" style={{ color: 'red' }}>{items.te1[1]}</p>
                                    </div>

                                    <div className="contentqr" >
                                        <img src={require('../assets/kbank2.png')} />
                                        <p>ธนาคารกสิกรไทย (Kbank)</p>
                                    </div>
                                    <div className="contentqr">
                                        <img src={require('../assets/SCB.png')} />
                                        <p className="scb">ธนาคารไทยพานิชย์ (SCB)</p>
                                    </div>
                                    <div className="contentqr">
                                        <img src={require('../assets/กรุงไทย.png')} />
                                        <p className="ktb">ธนาคารกรุงไทย (KTB)</p>
                                    </div>

                                </tbody>
                                <tbody >
                                    <tr className="top">
                                        <td colSpan={2}>
                                            <>
                                                {/*         <Col span={24} style={{ marginLeft: '-13px' }} >
                                                    <img style={{ margin: '21px' }} src={"" + url + "/" + textId + "/" + suffix + "/" + ref1 + "/" + items.te1[1] + "/" + amount} />
                                                </Col>
                                               */}
                                            </>
                                            <Col span={24} style={{}} >
                                                <img className="qr" style={{}} src={"" + urlqr + "/loans/qr/" + textId7Eleven + "/" + suffix + "/" + ref1 + "/" + items.te1[1] + "/" + amount} />
                                            </Col>
                                        </td>
                                    </tr>

                                    <p>สำหรับชำระผ่าน เคาน์เตอร์เซอร์วิส(7 Eleven)</p>
                                    <div className="contentqr1">
                                        <p className="tax">เลขประจำตัวผู้เสียภาษี:</p>
                                        <p style={{ color: 'red' }}>{textId7Eleven}{suffix}</p>
                                    </div>
                                    <div className="contentqr1">
                                        <p className="ref">ref1:</p>
                                        <p className="ref1" style={{ color: 'red' }}>{ref1}</p>
                                    </div>
                                    <div className="contentqr1">
                                        <p className="ref">ref2:</p>
                                        <p className="ref2" style={{ color: 'red' }}>{items.te1[1]}</p>
                                    </div>
                                </tbody>
                            </table>
                        )
                    })

                }



                <div className="contentqr">
                    <img src={require('../assets/tesco-lotus.png')} />
                    <p className="tesco">เทสโก้โลตัส และเทสโก้โลตัสเอ็กซ์เพรส</p>
                </div>
                <div className="contentqr">

                    <img src={require('../assets/7-11.png')} />
                    <img className="imgser" src={require('../assets/counter-service.png')} />

                    <p className="pser">เคาน์เตอร์เซอร์วิส (7-11)</p>

                </div>
            </div>


        </>

    )
}

export default Slip
