import React from 'react'
import { Route, Redirect } from "react-router-dom";

const LoginRoute = ({ children }) => {
   
   const aa = localStorage.getItem('user')

  
    return <Route>{aa ? children : <Redirect to='/login' />}</Route>
}

export default LoginRoute
