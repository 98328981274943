import React from 'react'
import { Switch, Route } from "react-router-dom";
import Line from '../components/Line';
import Cardd from '../components/Card';
import  Login  from '../components/Login';
import  LoginRoute  from './LoginRoute';
import  LogoutRoute  from './LogoutRoute';
import Logout from '../components/Logout';
import Example from '../components/Example';
import Pdf from '../components/pdf';

import Re from '../components/re';


const Router = () => {
   
    return (
        <div>      
            <Switch >
            <Route exact path='/'>
            <Login></Login>
                 </Route>
            <Route exact path='/pdf'>
             <Re/>
                 </Route>
                 <Route exact path='/line'>
                    <Line></Line>
                </Route>
                <Route  path='/logout'>
                    <Logout></Logout>
                </Route>
                <LogoutRoute path='/login'>
                    <Login></Login>
                </LogoutRoute>
                <LoginRoute path='/card'>
                <Example></Example>
                    <Cardd/>
                </LoginRoute>
                      
            </Switch>
        </div>
    )
}

export default Router
