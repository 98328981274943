import React, { useState, useEffect, useCallback } from 'react'
import { useHistory } from "react-router-dom";
import axios from 'axios'


const liff = window.liff;

const Line = () => {

  //  const [USERID_LINE, setUSERID_LINE] = useState('')

    const history = useHistory()
    const url = 'http://localhost:5000';


    const feed = async() => {
       
         await   liff.init({ liffId: `1656680773-QrJzBv65` })
            if (liff.isLoggedIn()) {
                liff.getProfile().then(profile => {
                    const USERID_LINE = profile.userId
                    console.log("1",USERID_LINE )
                    axios.post(`${url}/checkidline`, { USERID_LINE })
                        .then(res => {
                           // console.log(res.data)
                            if (res.data.USERID_LINE !=0) {
                                console.log("2")
                                localStorage.setItem("user", JSON.stringify(res.data));
                                history.push('/card')
                              
                            }else{
                                console.log("3",res.data.USERID_LINE1)
                                const ID_LINE=1
                                localStorage.setItem("userline", JSON.stringify(res.data.USERID_LINE1));
                                history.push('/login')
                            }

                        })

                })
                    .catch((err) => {
                        console.log('error111', err);
                    });
            }
            else {
                console.log("4")
                liff.login()

            }
       
    }


    useEffect(() => {

        feed()

        return () => {

        }
    }, [])


    return <div >
    </div>


}

export default Line
