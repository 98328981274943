import React, { Component } from 'react'
import ReactToPrint from 'react-to-print';
import {
    exportComponentAsJPEG,
    exportComponentAsPDF,
    exportComponentAsPNG
  } from "react-component-export-image";
class Pdf extends Component {


    render() {

    return (
        <div className="invoice-box">
           <MyComponent></MyComponent>
        </div>
    )
}
}

class ComponentToPrint extends React.Component {
    render() {
      return (
        <div className="App" >
          <h3 style={{ textAlign: "center" }}>ใบรับเงิน</h3>
          <div className="contentpdf">
          <img src={require('../assets/CAL-Logo(ใส).png')} style={{ width: '100%', maxWidth: 175 ,marginLeft: 110}} />
            <div className="row">
              <div className="col-sm-12">
                <div className="contentpdf">
                  <p>วันที่พิม:</p>
                  <p className="ptopPdf">10.02.2021</p>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="contentpdf">
                  <p>วันที่รับเงิน:</p>
                  <p className="ptopPdf">10.02.2021</p>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="contentpdf">
                  <p>เลขที่สัญญา:</p>
                  <p className="ptopPdf">8-0004673</p>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="contentpdf">
                  <p>ทะเบียนรถ/เลขที่ดิน:</p>
                  <p className="ptopPdf">4232(1)</p>
                </div>
              </div>
            </div>
          </div>
  
          <div className="row">
            <div className="col-sm-12">
              <div className="contentKong">
                <p>คงเหลือยกมา:</p>
                <p className="ptopPdf">4232(1)</p>
                <td>บาท</td>
              </div>
            </div>
          </div>
          <table cellpadding="0" cellspacing="0">
            <tr class="information">
              <td colspan="2">
                <table>
                  <tr class="heading">
                    <td>ยอดเงินรับจากลูกหนี้:</td>
                    <td>Price</td>
                  </tr>
                  <tr class="item">
                    <td>จำนวนเงินที่รับชำระ :</td>
                    <td>บาท</td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <table cellpadding="0" cellspacing="0">
            <tr class="information">
              <td colspan="2">
                <table>
                  <tr class="heading">
                    <td>รายการหักลูกหนี้:</td>
                    <td>Price</td>
                  </tr>
                  <tr class="item">
                    <td>เบี้ยปรับ:</td>
                    <td>บาท</td>
                  </tr>
                  <tr class="item">
                    <td>ค่าธรรมเนียมล่าช้า:</td>
                    <td>บาท</td>
                  </tr>
  
                  <tr className="item">
                    <td>คงเหลือเบี้ยปรับและค่าธรรมเนียม:</td>
                    <td>บาท</td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <div className="contentK">
            <h1 className="justify-center">รับสุทธิ: </h1>
            <h2>1000</h2>
            <p>THB </p>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="contentKong">
                <p>ยอดคงเหลือ:</p>
                <p>บาท</p>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
  class MyComponent extends React.Component {
    constructor(props) {
      super(props);
      this.componentRef = React.createRef();
    }
  
    render() {
      return (
        <React.Fragment>
              
          <ComponentToPrint ref={this.componentRef}  />
         
          <button onClick={() => exportComponentAsJPEG(this.componentRef)}>
            Export As JPEG
          </button>
          <button onClick={() => exportComponentAsPDF(this.componentRef)}>
            Export As PDF
          </button>
          <button onClick={() => exportComponentAsPNG(this.componentRef)}>
            Export As PNG
          </button>
        </React.Fragment>
      );
    }
  }


export default Pdf
