import React, { useState, useCallback } from 'react'
import { useHistory } from "react-router-dom";
import axios from 'axios'


const Login = () => {

  
    
    const [user, setUser] = useState({ CUSCOD: '', PASSWORD: '' })
    
    const userline = JSON.parse(localStorage.getItem("userline"));
  //  console.log(JSON.parse(localStorage.getItem("userline")))
    const [Userline, setUserline] = useState({ CUSCOD: user.CUSCOD, userline: userline })
    const [dataa, setdataa] = useState(true)
    const history = useHistory()
    const url = 'https://asia-east2-fir-express-c1aec.cloudfunctions.net/aa';

    const handleChange = async (e) => {
        const { name, value } = e.target       
        setUser({ ...user, [name]: value })
        if(name==='CUSCOD'){
            setUserline({...Userline,[name]: value, userline: userline })
        }
            
        
      
    }
    
  
    const fee = useCallback(() => {
     //   console.log(userline)  
      if(userline!==null){
        axios.post(`${url}/login`, user)
        .then(res => {
          //  console.log(res.data) 
          //console.log(res)   
            if (res.data.token) {  

                axios.post(`${url}/idline`,Userline)
                localStorage.setItem("user", JSON.stringify(res.data));
                history.push('/card')

            } 
        })
        .catch(e => {
            setdataa(false)
        
        })
   
      }else
      {
        
        axios.post(`${url}/login`, user)
            .then(res => {   
             //  console.log(res)                 
                if (res.data.accessToken) {               
                    localStorage.setItem("user", JSON.stringify(res.data));
                    history.push('/card')
                } 
            })
            .catch(e => {
                setdataa(false)
            
            })
    }
    }, [user])

    return (

        <div className="container">
            <div className="row mt-4">

                <div className="col-12 col-md-6 offset-md-3">
                    {dataa === false &&
                        <div className="alert alert-danger" role="alert">
                            เลขบัตรประชาชน หรือ รหัสผ่านผิด
                </div>
                    }
                    <h2 className="my-4 text-center">LOGIN</h2>
                    <form onSubmit={e => {
                        e.preventDefault()
                        fee()

                    }}>
                        <div className="form-group">
                            <label >เลขบัตรประชาชน</label>
                            <input

                                className="form-control"
                                aria-describedby="emailHelp"
                                placeholder="เลขบัตรประชาชน"
                                name="CUSCOD"
                                onChange={handleChange}
                                value={user.CUSCOD}

                            />
                        </div>
                        <div className="form-group">
                            <label >Password</label>
                            <input
                                type="password"
                                className="form-control"
                                name="PASSWORD"
                                onChange={handleChange}
                                value={user.PASSWORD}
                                placeholder="ใส่ 4 ตัว สุดท้าย ของเลขบัตรประชาชน"
                            />
                        </div>
             <p style={{color: 'red'}}>* password ใส่ 4 ตัว สุดท้าย ของเลขบัตรประชาชน </p>
                        <button className="btn btn-primary">
                            Submit
            </button>
                    </form>

                </div>
            </div>

        </div>





    )
}

export default Login
