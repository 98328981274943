import React,{ useEffect} from 'react'
import { useHistory } from "react-router-dom";
const Logout = () => {
    const history = useHistory()
    


 var  logout = () =>{
    localStorage.clear();
    history.push("/login");
   }

   useEffect(() => {

    logout();
    
   
}, [])
   

    return (
        <div>           
        </div>
    )
}

export default Logout
