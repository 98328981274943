import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import axios from 'axios'
import '../App.css';
import { saveAs } from 'file-saver';
import * as loadingData from './loading.json'
import * as successData from './success.json';
import FadeIn from "react-fade-in";
import Lottie from "react-lottie";
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal'
import 'bootstrap/dist/css/bootstrap.min.css';


const liff = window.liff;
const Slip1 = () => {
    const [All, setAll] = useState({ PAYDT: '', NETPAY: '', PAYINT: '', PAYAMT: '' })

    const history = useHistory()
    const { user, ma } = useParams()
    const [USER, setUSER] = useState([])
    const [b, setb] = useState(0)
    const [TotalBALANC, setTotalBALANC] = useState([])
    const [TotalBalanc, setTotalBalanc] = useState([])
    const url = 'https://asia-east2-fir-express-c1aec.cloudfunctions.net/aa';
    const auth1 = JSON.parse(localStorage.getItem("cus"));



    const feed1 = async () => {
        const data = []
        await axios.get(`${url}/table/${user}/${ma}`)
            .then(res => {
                res.data.forEach(element => {
                    data.push(element)
                })
            
                const PAYAMTPlus = data.map((sum) => sum.PAYAMT).reduce((ssum, nember) => ~~ssum + ~~nember) - data[0].PAYAMT
                const PAYAMTplus = data.map((sum) => sum.PAYAMT).reduce((ssum, nember) => ~~ssum + ~~nember)
                const Ba = ~~data[0].BALANC - PAYAMTPlus
                const BA = ~~data[0].BALANC - PAYAMTplus
                setTotalBalanc(Ba)
                setTotalBALANC(BA)
                setUSER(data)



            }).catch(() => {

            });

    }








    useEffect(() => {

        const jj = auth1.find(u => u.CONTNO === user)
        if (jj) {
            feed1()
        } else {
            history.push('/card')
        }

        return () => {

        }
    }, [user, history])



    return (
        <div className="containertable">
            <div className="contentslip1">
                <p className='pslip1'>ลูกหนี้คงเหลือ</p>
                <p className='pslip2' style={{ color: 'red' }}>{TotalBALANC <= 0 && "0"}{TotalBALANC > 0 && Number(Math.round(TotalBALANC)).toLocaleString('en')}</p>
                <p className='pslip3'>บาท</p>
            </div>
            <div style={{ textAlign: 'center', color: 'red' }}>
                *ข้อมูลการชำระเงินล่าช้า 1-2 วัน
          </div>
            <div style={{ textAlign: 'center', color: 'red' }}>
                *สำหรับ Android ดาวน์โหลดผ่าน Chrome เท่านั้น
          </div>
            <div style={{ textAlign: 'center', color: 'red' }}>
                *สำหรับ IOS หรือ iphone ดาวน์โหลดผ่าน Safari เท่านั้น
          </div>
            <table className="table table-sm" style={{ textAlign: 'center', border: '1px solid #eee' }}>
                <thead>
                    <tr>

                      {/*<th scope="col">งวดที่ชำระ</th>*/}
                        <th scope="col">วันที่ชำระ</th>
                        <th scope="col">จำนวนเงินที่ชำระ</th>
                        <th scope="col">เบี้ยปรับ</th>
                        <th scope="col">ค่าธรรมเนียมล่าช้า</th>
                        <th scope="col">รวมเบี้ยปรับและค่าธรรมเนียมล่าช้า</th>
                        <th scope="col">รับค่างวดสุทธิ</th>
                    </tr>
                </thead>
                <tbody>
                    {

                        USER.map((item, i) => {


                            return (

                                <tr key={i}>
                                  {/*  <th scope="row">{item.L_PAY}</th>*/}
                                    <td>{item.PAYDT}</td>
                                    <td>{Number(Math.round(item.NETPAY)).toLocaleString('en')}</td>
                                    <td>{Number(Math.round(item.PAYINT * 0.415667)).toLocaleString('en')}</td>
                                    <td>{Number(Math.round(item.PAYINT * 0.5843337)).toLocaleString('en')}
                                        <div>

                                            <Example headline={item} USER={USER} index={i} MA={ma}></Example>
                                        </div>
                                    </td>
                                    <td>{Number(Math.round(item.PAYINT)).toLocaleString('en')}</td>
                                    <td>{Number(Math.round(item.PAYAMT)).toLocaleString('en')}</td>
                                </tr>

                            )

                        })

                    }

                </tbody>


            </table>



        </div>


    )
}



export default Slip1




function Example({ headline, USER, index, MA }) {
    const url = 'https://asia-east2-fir-express-c1aec.cloudfunctions.net/aa';
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [img, setImg] = useState([]);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [selectetdFile, setSelectedFile] = useState([]);
    const [isLoading, setisLoading] = useState(false);
    const [isLoading1, setisLoading1] = useState(false);
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingData.default,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const defaultOptions2 = {
        loop: true,
        autoplay: true,
        animationData: successData.default,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    const Downloadimg = (() => {
        axios.get(`${url}/fetch-img`, { responseType: 'blob' })
            .then((res) => {
                const pdfBlob = new Blob([res.data], { type: 'image/png' });
                saveAs(pdfBlob, 'newimg.png');
            })
    })
   const Downloadpdf = (() => {
       axios.get(`${url}/fetch-pdf`, { responseType: 'blob' })
           .then((res) => {
               const pdfBlob = new Blob([res.data], { type: 'application/pdf' });
               saveAs(pdfBlob, 'newpdf.pdf');
           })
   })
    const createAndDownloadPdf = async input => {

        setLoading(false);
        setSuccess(false);
        setShow(true)


        const awePlus = USER.filter((awe, i) => i >= index)

        const PAYAMTPlus = awePlus.map((sum) => sum.PAYAMT).reduce((ssum, nember) => ~~ssum + ~~nember) - awePlus[0].PAYAMT
        const PAs = awePlus.map((sum) => sum.PAYAMT).reduce((ssum, nember) => ~~ssum + ~~nember)
        const Ba = ~~awePlus[0].BALANC - PAYAMTPlus
        const BA = ~~awePlus[0].BALANC - PAs
        var arr = new Array();


        if (MA === 'LSFHP') {
            arr.push({ ...input, REGNO: input.STRNO, Total: Ba, toTal: BA });
        } else {
            arr.push({ ...input, Total: Ba, toTal: BA })
        }

        setSelectedFile("" + url + "/img/" + arr[0].NETPAY + "/ " + arr[0].PAYINT + "/" + arr[0].PAYDT + "/" + arr[0].L_PAY + "/" + arr[0].PAYAMT + "/" + arr[0].CONTNO + "/" + arr[0].BALANC + " /" + arr[0].REGNO + " /" + arr[0].STRNO + "/" + arr[0].Total + "/" + arr[0].toTal + "")




        await axios.post(`${url}/create-img`, arr).then(() => {
            setLoading(true);
            setisLoading(true);
        })
       await axios.post(`${url}/create-pdf`, arr).then(() => {
           setisLoading1(true);
       })
        {/* 
    await axios.get(`${url}/fetch-pdf`, { responseType: 'blob' })
    .then((res) => {
        const pdfBlob = new Blob([res.data], { type: 'application/pdf' });
       
        var reader = new FileReader();
        if (pdfBlob) {
          reader.readAsDataURL(pdfBlob);
          reader.onload = () => {
            var Base64 = reader.result;
            console.log(Base64);
            saveAs(Base64, 'newPdf.pdf');
            setFileBase64String(Base64);
          };
          reader.onerror = (error) => {
            console.log("error: ", error);
          };
        }


    })
     
  */}

    }


    return (
        <>

            <Button variant="success" size="sm" onClick={(e) => createAndDownloadPdf(headline, e)}>
                Download
                </Button>



            <Modal show={show}
                size="lg"
                onHide={handleClose}
                centered
            >
                <Modal.Header closeButton>
                    {/*  <div style={{ display: "flex" }}>

                {!loading ? (
                <Lottie options={defaultOptions} height={140} width={140} />
                ) : (
                 <Lottie options={defaultOptions2} height={140} width={140} />
                )}
</div>*/}
                </Modal.Header>
               <Modal.Body>
                    <div>
                        {!loading ? (
                            <Lottie options={defaultOptions} height={140} width={140} />
                        ) : (
                            <>
                                <img style={{ width: '100%' }} src={"" + selectetdFile + ""} />

                            </>
                        )}

                    </div>

                </Modal.Body>

                {!liff.isInClient() ? (
                    <>
                        <Button style={{ margin: '10px' }} variant="success" size="sm" onClick={Downloadimg} disabled={isLoading ? '' : 'disabled'}  >
                            Download png
                </Button>
                       <Button style={{ margin: '10px' }} variant="success" size="sm" onClick={Downloadpdf} disabled={isLoading1 ? '' : 'disabled'}>
                           Download pdf
               </Button>
                    </>
                ) : (
                    <Button variant="success" size="sm" onClick={() => {
                        liff.openWindow({
                            url: "https://cal2009.web.app/login",
                            external: true
                        });
                    }}>
                        Download
                    </Button>

                )}

            </Modal>
        </>
    );
}