import React, { useEffect, useState, useCallback, useRef } from 'react'
import axios from 'axios'
import * as loadingData from './loading.json'
import Lottie from "react-lottie";
import { useParams, useHistory } from 'react-router-dom'
import { Row, Col } from 'antd';
import { PrinterTwoTone } from '@ant-design/icons';
import ReactToPrint from 'react-to-print';
import * as dayjs from 'dayjs'
import '../App.css';
const Re = () => {
    const today = new Date();
    const { user, ma } = useParams()
    const [loading, setLoading] = useState(false);
    const [USER, setUSER] = useState([])
    const [users1, setUsers1] = useState([]);
    const [users2, setUsers2] = useState([]);
    const [cus_user, setCus_user] = useState([]);
    const [damt, setDamt] = useState([]);
    const [day, setDay] = useState([]);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingData.default,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    
    
    const url = 'https://asia-east2-fir-express-c1aec.cloudfunctions.net/aa';
    const feed1 = async () => {
        setLoading(false);
        const date = dayjs().add(7, 'day').format('YYYY-MM-DD')
        await axios.get(`${url}/recontno/getcontno/` + user)
            .then(res => {
            //   console.log(res.data.custmast[0])
                setUSER(res.data.bu1[0])
                setUsers1(res.data.re1)
                setUsers2(res.data.re2)
                setDamt(res.data.bu1[0].DAMT) 
                setDay(date)              
                setCus_user(res.data.custmast[0])
                setLoading(true);
            }).catch(err => {

            });


    }

    const linkToPrint = () => {
        return (
            <PrinterTwoTone style={{ marginLeft: '37em', fontSize: '25px', color: '#08c' }} />
        )
    }
    const componentRef = useRef();

    useEffect(() => {
        feed1()

        return () => {

        }
    }, [])



    return (
        <>
            <ReactToPrint trigger={linkToPrint} content={() => componentRef.current} />

            {!loading ? (
                <Lottie options={defaultOptions} height={140} width={140} />
            ) : (
                <div className="invoice-box" style={{ marginTop: '3rem' }} ref={componentRef}>
                    <table cellPadding={0} cellSpacing={0}>
                        <tbody>
                            <tr className="top">
                                <td colSpan={2}>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <Row>
                                                    <Col span={10} style={{ textAlign: 'center' }}>
                                                        <div ><img src={require('../assets/CAL-Logo(ใส).png')} style={{ width: '100%', maxWidth: 156 }} /></div>
                                                    </Col>
                                                    <Col span={14} style={{ marginLeft: '-10px', marginTop: '30px' }}>
                                                        <Row >
                                                            <Col span={12} style={{ textAlign: 'center' }} >
                                                                <div >
                                                                    ตัดสด ณ วันที่:
                                                                </div>
                                                            </Col>
                                                            <Col span={12} style={{ textAlign: 'left' }} >
                                                                <div >
                                                                    {today.getDate()}/{today.getMonth() + 1}/{today.getFullYear()}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row >
                                                            <Col span={12} style={{ textAlign: 'center' }} >
                                                                <div >
                                                                    เลขที่สัญญา:&nbsp;
                                                                </div>
                                                            </Col>
                                                            <Col span={12} style={{ textAlign: 'left' }} >
                                                                <div >
                                                                    {cus_user.CONTNO}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row >
                                                            <Col span={12} style={{ textAlign: 'center' }} >
                                                                <div >
                                                                    ชื่อ-สกุล:&nbsp;
                                                                </div>
                                                            </Col>
                                                            <Col span={12} style={{ textAlign: 'left' }} >
                                                                <div >
                                                                    {cus_user.NAME1}  {cus_user.NAME2}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row >
                                                            <Col span={12} style={{ textAlign: 'center' }} >
                                                                <div >
                                                                    เกรด:&nbsp;
                                                                </div>
                                                            </Col>
                                                            <Col span={12} style={{ textAlign: 'left' }} >
                                                                <div >
                                                                    {cus_user.GRDCOD}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>

                            </tr>
                            <tr className="information">
                                <td colSpan={2}>
                                    <table>
                                        <tbody>

                                            <tr className="item">
                                                <td style={{ textAlign: 'center', width: '125px' }}>
                                                    ทะเบียนรถ:
                                                </td>
                                                <td style={{ textAlign: 'center', width: '90px' }}> {cus_user.REGNO} </td>

                                                <td style={{ textAlign: 'center', width: '50px' }}>
                                                    ยี่ห้อ:
                                                </td>
                                                <td style={{ textAlign: 'center', width: '95px' }}>
                                                    {cus_user.TYPE}
                                                </td>
                                                <td style={{ textAlign: 'center', width: '50px' }}>
                                                    รุ่น:
                                                </td>
                                                <td style={{ textAlign: 'center', width: '300px' }}>
                                                    {cus_user.MODEL}
                                                </td>
                                            </tr>
                                            <tr className="item">

                                                <td style={{ textAlign: 'center', width: '40px' }}>
                                                    สี:
                                                </td>
                                                <td style={{ textAlign: 'center', width: '0px' }}>
                                                    {cus_user.COLOR}
                                                </td>
                                                <td style={{ textAlign: 'center' }}>
                                                    ปี:
                                                </td>
                                                <td style={{ textAlign: 'center' }}>
                                                    {cus_user.MANUYR}
                                                </td>
                                                <td style={{ textAlign: 'center', width: '130px' }}>
                                                    เลขเครื่อง:
                                                </td>
                                                <td style={{ textAlign: 'center' }}>
                                                    {cus_user.STRNO}
                                                </td>
                                            </tr>
                                            <tr className="item">


                                                <td style={{ textAlign: 'center', width: '150px' }}>
                                                    เลขตัวถัง:
                                                </td>
                                                <td style={{ textAlign: 'center' }}>
                                                    {cus_user.ENGNO}
                                                </td>


                                            </tr>



                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr className="information">
                                <td colSpan={2}>
                                    <table>
                                        <tbody>

                                            <tr className="item" >

                                                <td style={{ textAlign: 'center'}}>
                                                    เบอ:
                                                </td>
                                                <td style={{ textAlign: 'center', width: '150px'  }}>
                                                    {cus_user.TELP}
                                                </td>
                                                <td style={{ textAlign: 'center', width: '45px' }}>
                                                    ค่างวด:
                                                </td>
                                                <td style={{ textAlign: 'center', width: '75px' }}>{damt}</td>


                                            </tr>
                                            <tr className="item" >

                                                <td style={{ textAlign: 'center', width: '175px' }}>
                                                    เจ้าหน้าที่ทำเคส:
                                                </td>
                                                <td style={{ textAlign: 'center', width: '160px' }}>{cus_user.SALCOD}({cus_user.NIKNAME})</td>

                                            </tr>





                                        </tbody>
                                    </table>
                                </td>
                            </tr>

                            <tr className="heading">

                                <td style={{ textAlign: 'center' }}>รายละเอียด:</td>
                                <td style={{ textAlign: 'center' }}>Price</td>

                            </tr>

                            <tr className="item">
                                <td style={{ textAlign: 'center' }}>ยอดจัดครั้งที่แล้ว:</td>
                                <td style={{ textAlign: 'center' }}> {Number(Math.round(USER.tcshprc)).toLocaleString('en')} บาท</td>
                            </tr>


                            <tr className="item" >
                                <td style={{ textAlign: 'center' }}>ราคาเช่าซื้อครั้งที่แล้ว : </td>
                                <td style={{ textAlign: 'center' }}>{Number(Math.round(USER.balanc)).toLocaleString('en')} บาท</td>
                            </tr>

                            <tr className="item">
                                <td style={{ textAlign: 'center' }}>ยอดที่ชำระมาแล้วทั้งหมด:</td>
                                <td style={{ textAlign: 'center' }}>{Number(Math.round(USER.totalpayment)).toLocaleString('en')} บาท</td>
                            </tr>
                            <tr className="item">
                                <td style={{ textAlign: 'center' }}>ล/น คงเหลือรวม:</td>
                                <td style={{ textAlign: 'center' }}>{Number(Math.round(USER.neekong)).toLocaleString('en')} บาท</td>
                            </tr>
                            <tr className="item">
                                <td style={{ textAlign: 'center' }}>เบี้ยปรับ:</td>
                                <td style={{ textAlign: 'center' }}>{Number(Math.round(USER.beeypup)).toLocaleString('en')} บาท</td>
                            </tr>
                            <tr className="item">
                                <td style={{ textAlign: 'center' }}>ลูกหนี้อื่นๆ:</td>
                                <td style={{ textAlign: 'center' }}>{Number(Math.round(USER.OTHR)).toLocaleString('en')} บาท</td>
                            </tr>
                            <tr className="item">
                                <td style={{ textAlign: 'center' }}>จำนวนงวดที่ผ่อนมาแล้ว:</td>
                                <td style={{ textAlign: 'center' }}>{USER.NOPAY} งวด</td>
                            </tr>
                            <tr className="item">
                                <td style={{ textAlign: 'center' }}>จำนวนงวดทั้งหมด:</td>
                                <td style={{ textAlign: 'center' }}>{USER.nopay} งวด</td>
                            </tr>
                            <tr className="item">
                                <td style={{ textAlign: 'center' }}>ตัดสด งวดที่:</td>
                                <td style={{ textAlign: 'center' }}>{USER.req_no}</td>
                            </tr>




                        </tbody>
                    </table>
                    <br />
                    <div className="content">
                        <h1 className="justify-center">ยอดรีไฟแนนซ์เพื่อจัดใหม่: </h1>
                        <h2>{Number(Math.round(Math.min(users1, users2))).toLocaleString('en')}</h2>
                        <p>บาท</p>
                    </div>
                    <br />
                    <div className="content">
                        <h1 className="justify-center">ยอดจัดใหม่: </h1>
                        <h2>............................................</h2>
                        <p>บาท</p>
                    </div>
                    <br />
                    <div className="content">
                        <h1 className="justify-center">จำนวนงวด: </h1>
                        <h2>............................................</h2>
                        <p>งวด</p>
                    </div>
                    <div className="content" >
                        <h1 style={{ color: 'red' }}>ใบนี้หมดอายุ ณ วันที่ : </h1>
                        <h2 style={{ color: 'red' }}>{day}</h2>
                    </div>

                </div>
            )}


        </>

    )
}

export default Re